<template>
    <div class="container">
        <div class="static-page" style="margin-bottom: 50px;">
            <h1 class="title" style="font-size: 28px; max-width: 1000px; margin-inline: auto;">Политика сервиса
                бронирования отелей alamat.ru в области обработки и обеспечения безопасности персональных данных
                клиентов</h1>
            <h1>Общие положения</h1>
            <p>1.1. Политика о защите персональных данных сервиса бронирования отелей Alamat.ru (далее «Политика»)
                направлена
                на сохранение конфиденциальности персональных данных клиентов, пользующихся услугами сервиса
                бронирования отелей www.alamat.ru (далее «Сервис») в целях бронирования номеров отелей (далее «Клиенты),
                защиту прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиту
                прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
            <p>1.2. Обработка персональных данных Клиентов осуществляется Оператором Сервиса компанией ООО «Аламат»
                (ОГРН 1227700220989).</p>
            <p>1.3. Оператор обеспечивает защиту обрабатываемых персональных данных от несанкционированного доступа и
                разглашения, неправомерного использования или утраты в соответствии с требованиями Федерального закона
                от 27 июля 2006 г. № 152- ФЗ «О персональных данных» (далее «Закон о персональных данных») и Приказа
                ФСТЭК России от 18.02.2013 N 21 «Об утверждении Состава и содержания организационных и технических мер
                по обеспечению безопасности персональных данных при их обработке в информационных системах персональных
                данных».</p>
            <p>1.4. Настоящая Политика устанавливает перечень обрабатываемых персональных данных Клиентов, основные
                принципы, цели, способы, условия обработки персональных данных, функции и перечень действий Сервиса и
                его сотрудников, связанных с обработкой персональных данных Клиентов.</p>
            <p>1.5. Сервис может осуществлять обработку следующих персональных данных Клиентов: фамилия, имя,
                домашний/мобильный телефон, адрес личной электронной почты, год рождения, данные банковской карты, файлы
                cookie и иные персональные данные, указание которых необходимо для использования Сервиса, а также
                платежной информации, такой как имя, платежный адрес, номер счета и реквизиты платежной карты (включая
                номер карты, дату истечения срока действия и код безопасности) для платежей, обрабатываемых Оператором.
                В той мере, в какой любые платежи обрабатываются любыми другими третьими сторонами, такими как PayPal
                или Apple Pay, такая информация регулируется политикой конфиденциальности этих сторон.</p>
            <p>1.6. Оператор не осуществляет сбор и обработку персональных данных Клиента о его расовой, национальной
                принадлежности, политических взглядах, религиозных или философских убеждениях, частной жизни.</p>
            <p>1.7. Персональные данные Клиентов относятся к конфиденциальной информации ограниченного доступа.</p>
            <p>1.8. Обеспечение конфиденциальности персональных данных не требуется в случае их обезличивания, а также в
                отношении общедоступных персональных данных.</p>
            <p>1.9. Оператор имеет право вносить изменения в настоящую Политику. Новая редакция Политики вступает в силу
                с момента ее размещения на сайте, если иное не предусмотрено новой редакцией Политики. В случае
                существенного изменения условий настоящего Соглашения Оператор извещает об этом Клиентов путем
                размещения на Сайте соответствующего сообщения.</p>
            <p>1.10. Если вы не согласны с положениями настоящего документа о конфиденциальности, мы просим вас
                воздержаться от пользования нашими услугами. Продолжая использование Сервиса, Клиент даёт свое согласие
                Оператору в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» на
                осуществление следующих действий:</p>
            <p><b>обработка персональных данных</b> (фамилия, имя, дата и место рождения, паспортные данные, адрес места
                регистрации и проживания, гражданство, данные банковской карты, контактная информация,
                домашний/мобильный телефон, адрес личной электронной почты, иные персональные данные, в т.ч. файлы
                cookie, сбор и использование которых ведется на Сервисе в соответствии с настоящей Политикой);</p>
            <p><b>передача, в том числе трансграничная, персональных данных третьим лицам</b>, в том числе на территории
                иностранных государств, не обеспечивающих адекватной защиты прав субъектов персональных данных в
                соответствии с пп. 4 п. 4 ст. 12 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», в
                письменной либо электронной форме,</p>
            <br>

            <p>для целей оказания следующих услуг:</p>
            <ul style="list-style-type: disc">
                <li style="margin-left: 50px;">бронирования номеров в отелях и любых иных средствах размещения;</li>
                <li style="margin-left: 50px;">оказания любых иных туристских услуг;</li>
                <li style="margin-left: 50px;">заключения и исполнения договоров в пользу Клиента;</li>
                <li style="margin-left: 50px;">направления денежных средств на оплату заказываемых услуг;</li>
                <li style="margin-left: 50px;">участия в бонусных программах и программах лояльности Оператора, а также
                    его партнеров;
                </li>
                <li style="margin-left: 50px;">отправки сообщений (в том числе рекламных) посредством электронной
                    почты;
                </li>
                <li style="margin-left: 50px;">в иных целях, связанных с оказанием Клиенту заказываемых услуг;</li>
                <li style="margin-left: 50px;">передача Оператором полномочий по обработке персональных данных иному
                    лицу в соответствии с ч. 3 ст. 6 Закона о персональных данных.
                </li>
            </ul>

            <br>

            <p><b>Настоящее согласие дается Клиентом до полного исполнения обязательств Оператором и/или до истечения
                срока хранения персональных данных, установленного законодательством РФ, и может быть отозвано путем
                подачи Оператору письменного заявления. Клиент также даёт согласие на передачу своих персональных данных
                третьим лицам с целью их дальнейшей обработки с соблюдением всех принципов и правил обработки
                персональных данных, предусмотренных Федеральным законом N 152-ФЗ «О персональных данных». Решение о
                предоставлении своих персональных данных и согласие на их обработку дано своей волей и в своем интересе.
                Отказ от предоставления своих персональных данных влечет невозможность для Оператора оказать услуги.</b>
            </p>


            <h1>Термины и определения</h1>

            <p>2.1. Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или
                определяемому физическому лицу (субъекту персональных данных);</p>
            <p>2.2. Оператор – компания ООО «Аламат» (РФ), организующая и осуществляющая обработку персональных данных,
                а также определяющая цели обработки персональных данных, состав персональных данных, подлежащих
                обработке, действия (операции), совершаемые с персональными данными;</p>
            <p>2.3. Обработка персональных данных - любое действие (операция) или совокупность действий (операций),
                совершаемых с персональными данными, включая сбор, запись, систематизацию, накопление, хранение,
                уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление,
                доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
            <p>2.4. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств
                вычислительной техники.</p>
            <p>2.5. Информационная система персональных данных (ИСПД) – совокупность содержащихся в базах данных
                персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</p>
            <p>2.6. Уничтожение персональных данных – действия, в результате которых становится невозможным восстановить
                содержание персональных данных в информационной системе персональных данных и (или) в результате которых
                уничтожаются материальные носители персональных данных.</p>
            <p>2.7. Иные термины и определения, встречающиеся в настоящей Политике, подлежат толкованию в соответствии с
                положениями Закона о персональных данных.</p>

            <h1>Цели обработки персональных данных Клиентов Сервиса</h1>

            <p>Сервис обрабатывает персональные данные Клиентов в следующих целях:</p>

            <p>а) Бронирования. Персональные данные используются для оформления и управления бронированиями номеров
                отелей, совершенных он-лайн и/или посредством использования клиентского сервиса Alamat.ru, в том числе
                для передачи данных о совершенных бронированиях в объекты размещения, а также заказа иных доступных на
                Сервисе туристских услуг.</p>
            <p>б) Поддержка Клиентов. В случае необходимости Сервис оказывает Клиентам необходимую помощь, включая выбор
                необходимого объекта размещения, оформления и завершения бронирования, решения проблемных ситуаций и
                ответов на иные вопросы. Записи телефонных разговоров хранятся в течение ограниченного периода времени и
                удаляются автоматически, если Оператор не посчитает нужным сохранить их на более длительный период (по
                обоснованной необходимости), в том числе в целях расследования случаев мошенничества.</p>
            <p>в) Улучшение качества услуг. Сервис ориентирован на постоянное улучшение качества предоставляемых услуг и
                разработку новых удобных инструментов для осуществления бронирований.</p>
            <p>г) Личное управление бронированиями. Создавая личный аккаунт на Сервисе, Клиенты приобретают возможность
                управлять своими бронированиями, использовать персональные предложения, участвовать в программах
                лояльности Сервиса, создавать закладки и сохранять необходимые параметры поиска.</p>
            <p>д) Маркетинг. Персональные данные Клиента могут быть использованы для рассылки новостей о продуктах и
                услугах для путешествий, индивидуальных предложений, информации по акциям и конкурсам, проводимым на
                Сервисе, предложения об участии в исследованиях. Клиенту предоставляется возможность отписаться от
                соответствующей рассылки в любое время.</p>
            <p>е) Надежность и безопасность услуг. Оператор может использовать данные в тестовых целях, для решения
                проблем и для улучшения функциональности и качества наших услуг в сфере онлайн-путешествий.</p>
            <p>ж) Юридические цели. Данные используются для обработки и разрешения правовых споров, для расследований по
                требованию государственных органов и соблюдения нормативно-правовых норм, а также для обеспечения
                соблюдения условий, на которых Сервис оказывает услуги по онлайн-бронированию.</p>
            <p>з) ADARA: файлы cookie ADARA собирают информацию о вашей туристической деятельности и предпочтениях,
                которая позволяет Оператору и/или одобренным сторонним компаниям взаимодействовать с вами посредством
                персонализированной, релевантной рекламы, когда вы просматриваете другие веб-сайты и платформы
                социальных сетей, а также через службы аналитики ADARA. Вы можете узнать больше об ADARA и отказаться от
                участия по адресу https://www.adara.com/privacy-promise.</p>
            <p>и) Google Analytics (Google Аналитика): файлы cookie Google Analytics, в том числе для рекламодателей,
                для измерения и оценки доступа и трафика через Сайт, а также для создания навигационных отчетов
                пользователей для администраторов Сайта. Google работает независимо от нас и имеет свою собственную
                политику конфиденциальности, с который мы настоятельно рекомендуем ознакомиться. Google может
                использовать информацию, собранную с помощью Google Analytics, для оценки активности пользователей на
                нашем сайте. Мы принимаем меры по защите технической информации, собранной нами в результате
                использования Google Analytics. Собранные данные будут использоваться только при необходимости решения
                технических вопросов, для администрирования Сайта и определения предпочтений пользователей. В этом
                случае данные будут использоваться в обезличенной форме. Мы не используем описанную информацию для
                идентификации пользователей. Пользователь имеет право отключить функции Google Analytics для
                рекламодателей, в том числе через настройки рекламных предпочтений для обычных и мобильных приложений
                или любым другим способом (например, с помощью инструмента отказа от рекламы NAI). Пользователь также
                имеет право использовать Блокировщик Google Аналитики (https://tools.google.com/dlpage/gaoptout/). Все
                необходимые данные о функциях Google Аналитики Пользователь может узнать по адресу
                https://support.google.com/analytics/answer/2700409?hl=ru.</p>


            <h1>Порядок и условия обработки персональных данных Клиентов</h1>

            <p>4.1. Персональные данные Клиентов Сервиса обрабатываются исключительно с использованием средств
                автоматизации.</p>
            <p>4.2. Обработка персональных данных осуществляется Сервисом с согласия Клиента как с момента совершения им
                бронирования, так и непосредственно после регистрации Клиента на Сервисе.</p>
            <p>4.3. Получение согласия Клиента на обработку его персональных данных является необходимым условием
                совершения Клиентом бронирования или регистрации на Сервисе. С содержанием документа «Согласие на
                обработку персональных данных» Клиент может ознакомиться в любой время, в том числе при оформлении
                бронирования.</p>
            <p>4.4. Все персональные данные следует получать от самого субъекта. Если персональные данные субъекта можно
                получить только у третьей стороны, то субъект должен быть уведомлен об этом и от него должно быть
                получено согласие, т.к. это является личной ответственностью Клиента.</p>
            <p>4.5. Некоторые данные могут собираться автоматически без участия Клиента, например, IP-адрес, дата и
                время, когда Клиент пользовался услугами Сервиса, информация об аппаратном и программном обеспечении, а
                также интернет-браузере, который Клиент использует.</p>
            <p>4.6. Персональные данные могут передавать следующим третьим лицам:</p>

            <ul style="list-style-type: disc">
                <li style="margin-left: 50px;">Объекту размещения, а также поставщику и исполнителю иных туристских
                    услуг. Данные о совершенном
                    бронировании, включая персональные данные Клиентов, передаются объекту размещения, в котором
                    Клиентом осуществлено бронирование, в том числе за границу. Такая информация может включать имя,
                    контактные данные, платежные реквизиты, имена гостей, путешествующих с Клиентом, и любые
                    предпочтения, которые были указаны во время бронирования. В случае возникновения споров, связанных с
                    бронированием, мы можем по необходимости предоставить администрации объекта размещения информацию о
                    процессе бронирования. Такая информация может включать копию вашего подтверждения бронирования в
                    качестве доказательства того факта, что бронирование было совершено.
                </li>
                <li style="margin-left: 50px;">Компетентным государственным органам. Сервис оставляет за собой право в
                    случаях, предусмотренных
                    законодательством Российской Федерации, раскрыть персональные данные Клиентов соответствующим
                    уполномоченным органам государственной и муниципальной власти.
                </li>
                <li style="margin-left: 50px;">Иным лицам, в том числе организациям, которые в соответствии с
                    заключенными договорами:
                </li>
            </ul>

            <p>- осуществляют страхование имущественных интересов физических и юридических лиц, связанных с финансовыми
                обязательствами и обязательствами по предоставлению услуг;</p>

            <p>- страховым организациям;</p>
            <p>- осуществляют списание денежных средств с банковской карты;</p>
            <p>- кредитным организациям (банкам);</p>
            <p>- партнерам Сервиса, осуществляющим предпринимательскую деятельность, аффилированным лицам Оператора, а
                также иным третьим лицам в случаях и в порядке, предусмотренном соответствующими договорами и
                законодательством РФ.</p>

            <p>При этом при передаче персональных данных вышеперечисленным лицам Сервис не несет дальнейшей
                ответственности за законность дальнейшей обработки персональных данных, осуществляемой этими лицами.
            </p>

            <p>4.7. Сервис осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
                изменение), извлечение, использование, передачу, в том числе трансграничную (распространение,
                предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.</p>
            <p>4.8. Хранение данных в форме, позволяющей определить Клиента, осуществляется не дольше, чем этого требуют
                цели их обработки, и они подлежат уничтожению по достижении целей обработки или в случае утраты
                необходимости в их достижении.</p>

            <h1>Функции Сервиса при осуществлении обработки персональных данных</h1>

            <p>5.1. При осуществлении обработки персональных данных Оператор принимает следующие меры, необходимые для
                выполнения обязанностей оператора персональных данных, предусмотренных законодательством Российской
                Федерации:
            </p>

            <ul style="list-style-type: disc">
                <li style="margin-left: 50px;">назначение лица, ответственного за организацию обработки и обеспечения
                    безопасности персональных данных;
                </li>
                <li style="margin-left: 50px;">принятие локальных нормативных актов в области обработки и защиты
                    персональных данных, в т.ч. настоящей Политики;
                </li>
                <li style="margin-left: 50px;">получение согласий субъектов персональных данных на обработку их
                    персональных данных, за исключением случаев, предусмотренных законодательством Российской Федерации;
                </li>
                <li style="margin-left: 50px;">обеспечение раздельного хранения персональных данных и их материальных
                    носителей, обработка которых осуществляется в разных целях и которые содержат разные категории
                    персональных данных;
                </li>
                <li style="margin-left: 50px;">хранение материальных носителей персональных данных с соблюдением
                    условий, обеспечивающих сохранность персональных данных и исключающих несанкционированный доступ к
                    ним;
                </li>
                <li style="margin-left: 50px;">организация режима обеспечения безопасности помещений, в которых
                    размещена информационная система, препятствующего возможности неконтролируемого проникновения или
                    пребывания в этих помещениях лиц, не имеющих права доступа в эти помещения;
                </li>
                <li style="margin-left: 50px;">обеспечение сохранности носителей персональных данных;</li>
                <li style="margin-left: 50px;">утверждение руководителем оператора документа, определяющего перечень
                    лиц, доступ которых к персональным данным, обрабатываемым в информационной системе, необходим для
                    выполнения ими служебных (трудовых) обязанностей;
                </li>
                <li style="margin-left: 50px;">обучение работников Оператора, непосредственно осуществляющих обработку
                    персональных данных, положениям законодательства РФ о персональных данных, в том числе требованиям к
                    защите персональных данных, документам, определяющим политику Оператора в отношении обработки
                    персональных данных, локальным актам по вопросам обработки персональных данных
                </li>
                <li style="margin-left: 50px;">использование сертифицированного антивирусного программного обеспечения с
                    регулярно обновляемыми базами;
                </li>
                <li style="margin-left: 50px;">использование средств защиты информации, прошедших процедуру оценки
                    соответствия требованиям законодательства Российской Федерации в области обеспечения безопасности
                    информации, в случае, когда применение таких средств необходимо для нейтрализации актуальных угроз.
                </li>
            </ul>

            <p>5.2. В соответствии с требованиями нормативных документов Оператором создана система защиты персональных
                данных (СЗПД), состоящая из подсистем правовой, организационной и технической защиты.</p>
            <p>5.3. Подсистема правовой защиты представляет собой комплекс правовых, организационно-распорядительных и
                нормативных документов, обеспечивающих создание, функционирование и совершенствование СЗПД.</p>
            <p>5.4. Подсистема организационной защиты включает в себя организацию структуры управления СЗПД,
                разрешительной системы, защиты информации при работе с сотрудниками, партнерами и сторонними лицами.</p>
            <p>5.5. Подсистема технической защиты включает в себя комплекс технических, программных,
                программно-аппаратных средств, обеспечивающих защиту персональных данных.</p>


            <h1>Положения о cookie-фалах</h1>

            <p>6.1. Оператор может использовать cookie-файлы и другие технологии отслеживания для поддержки работы
                Сервиса, анализа трафика или в рекламных целях. Файлы cookie, используемые на Сервисе, относятся к
                обезличенной информации и не позволяют идентифицировать Клиента и не относятся к персональным данным.
                Пользуясь Сервисом, Клиент подтверждает свое согласие на использование файлов cookie, описанных в
                настоящей Политике.</p>
            <p>6.2. Оператор использует следующие виды cookie-файлов:</p>

            <p><i>- Управление аккаунтом</i></p>
            <p>При заходе Клиента на Сайт происходит автоматическая генерация файлов cookie, что позволяют узнать, когда
                Клиент начал сессию на Сервисе и когда закончил. Сервис использует этот вид файлов cookie, чтобы
                определить, с какого аккаунта Клиент совершил вход, и какие опции ему доступны.</p>
            <p><i>- Настройки предпочтения региона и языка</i></p>
            <p>Этот вид файлов cookie используется при выборе Клиентом предпочтительного региона и языка для удобного
                использования Сервиса.</p>
            <p><i>- Основные cookie</i></p>
            <p>Используются, чтобы Клиент имел возможность передвигаться по страницам Сервиса и эффективно использовать
                его возможности.</p>
            <p><i>- Аналитические cookie</i></p>
            <p>Эти файлы cookie устанавливаются системами аналитики, которые собирают информацию о том, как Клиент
                использует Сервис. Данный вид файлов cookie используется исключительно в статистических целях.</p>
            <p><i>- Рекламные cookie (включая файлы cookie третьих лиц)</i></p>
            <p> Данный вид файлов cookie используется для того, чтобы собирать информацию о веб-сайтах и отдельных
                страницах, которые посещают Клиенты. Данные файлы cookie используются для того, чтобы отображать
                наиболее соответствующую интересам Клиента рекламу и осуществлять маркетинговые коммуникации. На Сервисе
                могут быть размещены рекламные файлы cookie партнеров.</p>
            <p>Если ранее Клиент использовал социальную сеть через свою учетную запись, то социальная сеть может в
                автоматическом режиме запомнить учетные данные Клиента. Если Клиент использует такие плагины, как
                «Нравится», «Опубликовать», «Поделиться», «Рассказать друзьям», то соответствующая информация передается
                в социальную сеть напрямую и сохраняется там. По такому же принципу работают плагины на Сервисе.
            </p>

            <p>6.3. Клиент может настроить все свои мобильные устройства на прием файлов cookie, уведомления о получении
                файлов cookie или отключение приема файлов cookie.</p>
            Настройки изменения или отключения файлов cookie могут варьироваться в зависимости от вида браузера: Клиент
            должен воспользоваться такими вкладками, как “помощь”, “настройки” или “предпочтения” в его/ её меню
            браузера.
            <p>6.4. Чтобы узнать больше о том, как работать с cookie-файлами или удалить их, Клиент может зайти на сайт
                allaboutcookies.org и в раздел «Справка»/«Помощь» своего браузера. Через настройки браузера (например,
                Internet Explorer, Safari, Firefox, Chrome) Клиент может выбрать, какие cookie-файлы принимать, а какие
                — отклонять. Расположение настроек зависит от того, какой браузер вы используете.</p>
            <p>Если Клиент решил отказаться от определенных технических и/или функциональных cookie-файлов, ему могут
                быть недоступны некоторые функции Сервиса.
            </p>

            <h1>Права субъектов персональных данных</h1>

            <p>7.1. Субъект персональных данных имеет право на получение информации, касающейся обработки его
                персональных данных, в том числе содержащей:
            </p>

            <ul style="list-style-type: disc">

                <li style="margin-left: 50px;">подтверждение факта обработки персональных данных оператором;</li>
                <li style="margin-left: 50px;">правовые основания и цели обработки персональных данных;</li>
                <li style="margin-left: 50px;">цели и применяемые оператором способы обработки персональных данных;</li>
                <li style="margin-left: 50px;">наименование и место нахождения оператора, сведения о лицах (за
                    исключением работников оператора), которые имеют доступ к персональным данным или которым могут быть
                    раскрыты персональные данные на основании договора с оператором или на основании федерального
                    закона;
                </li>
                <li style="margin-left: 50px;">обрабатываемые персональные данные, относящиеся к соответствующему
                    субъекту персональных данных, источник их получения, если иной порядок представления таких данных не
                    предусмотрен федеральным законом;
                </li>
                <li style="margin-left: 50px;">сроки обработки персональных данных, в том числе сроки их хранения;</li>
                <li style="margin-left: 50px;">порядок осуществления субъектом персональных данных прав;</li>
                <li style="margin-left: 50px;">информацию об осуществленной или о предполагаемой трансграничной передаче
                    данных;
                </li>
                <li style="margin-left: 50px;">наименование или фамилию, имя, отчество и адрес лица, осуществляющего
                    обработку персональных данных по поручению оператора, если обработка поручена или будет поручена
                    такому лицу;
                </li>
                <li style="margin-left: 50px;">иные сведения, предусмотренные законодательством РФ.</li>
            </ul>

            <p>7.2. Сведения, указанные в п. 7.1., предоставляются субъекту персональных данных при обращении по
                электронной почте hi@alamat.ru. Субъект персональных данных обязан предоставить Оператору доказательства
                принадлежности персональных данных, в отношении которых направляется запрос, именно данному субъекту.
                Такие сведения должны быть предоставлены субъекту персональных данных в доступной форме, и в них не
                должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за
                исключением случаев, если имеются законные основания для раскрытия таких персональных данных.</p>
            <p>7.3. Субъект персональных данных имеет право на отзыв своего согласия на обработку персональных данных, а
                также на их удаление. Для реализации данных прав субъект персональных данных должен направить
                соответствующее обращение по электронной почте info@alamat.ru. Субъект персональных данных обязан
                предоставить Оператору доказательства принадлежности персональных данных, в отношении которых
                направляется запрос, именно данному субъекту. Сервис рассматривает поступившее обращение и обеспечивает
                прекращение обработки персональных данных субъекта, а также удаляет персональные данные субъекта в
                течение 5 (пяти) рабочих дней с момента поступления обращения субъекта персональных данных.</p>
            <p>7.4. Уничтожение документов (носителей), содержащих персональные данные, производится путем сожжения,
                дробления (измельчения), химического разложения, превращения в бесформенную массу или порошок. Для
                уничтожения бумажных документов допускается применение шредера.</p>
            <p>7.5. Данные на электронных носителях уничтожаются путем стирания или форматирования носителя. Факт
                уничтожения персональных данных подтверждается документально актом об уничтожении носителей.</p>

            <h1>Контроль за соблюдением требований к защите персональных данных</h1>

            <p>8.1. Внутренний контроль за соблюдением структурными подразделениями Операторов законодательства
                Российской Федерации и локальных нормативных актов в области персональных данных осуществляется лицом,
                ответственным за организацию обработки и обеспечение безопасности персональных данных.</p>
            <p>8.2. Оператор оставляет за собой право исключения Клиента (удаление профиля Клиента на Сервисе) в случае
                нарушения Клиентом правил работы на Сервисе, а также некорректного поведения Клиента.</p>
            <p>8.3. Все сотрудники Оператора, осуществляющие обработку персональных данных, обязаны хранить тайну о
                сведениях, содержащих персональные данные, в соответствии с Политикой и требованиями законодательства
                РФ.</p>
            <p>8.4. Лица, виновные в нарушении требований настоящей Политики, несут предусмотренную законодательством РФ
                ответственность.</p>

            <br>

            <p><i>Редакция от 19 января 2021г.</i></p>


        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {}
    },
    async mounted() {

    },
    methods: {}
}
</script>